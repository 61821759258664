function isLocalNetwork(hostname: string): boolean {
  if (hostname === 'localhost' || hostname === '127.0.0.1' || hostname === '::1') {
    return true;
  }
  if (hostname.startsWith('192.168.') || hostname.startsWith('172.') || hostname.startsWith('10.')) {
    return true;
  }
  return false;
}

function url(fromUrl: URL, path: string): URL {
  const dest = new URL(fromUrl);
  if (isLocalNetwork(fromUrl.hostname)) {
    dest.port = '9292';
    dest.pathname = '/v1' + path;
  } else {
    dest.hostname = 'api.' + fromUrl.hostname;
    // Custom domain specifies '/v1' as the default stage
    dest.pathname = path;
  }
  return dest;
}

export function reportFeedbackUrl(reportIssuePageUrl: URL): string {
  return url(reportIssuePageUrl, '/feedback').href;
}

export function checkinDataUrl(checkinPageUrl: URL): string {
  return url(checkinPageUrl, checkinPageUrl.pathname).href;
}

// POST /v1/user/cancel_delete/{token}
export function cancelDeletingUserUrl(token: string, deletingUserUrl: URL): string {
  return url(deletingUserUrl, '/user/cancel_delete/' + token).href;
}
