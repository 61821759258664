import { useRouteError } from "react-router-dom";

export default function NotFoundPage() {
  const error: any = useRouteError();
  console.error(error);

  return (
    <div style={{ backgroundColor: '#004225' }}>
      <nav className="navbar is-success is-fixed-top" role="navigation" aria-label="main navigation" style={{ backgroundColor: '#004225' }}>
        <div className="navbar-brand">
          <a className="navbar-item" href="/">
            <img src="/pathtraq-logo.png" alt="Pathtraq" width="32" height="32" />
          </a>
        </div>
      </nav>
      <div style={{ height: '5vh' }}>  </div>
      <div id="detail">
        <div style={{ backgroundColor: '#004225' }}>
          <div className="container is-max-desktop" style={{ width: '100%', height: '100vh' }}>
            <section className="hero is-fullheight">
              <div className="hero-body">
                <div>
                  <p className="title has-text-white" >
                    Oops!
                  </p>
                  <p className="subtitle has-text-white">
                    Sorry, the specified page is not found.
                    <br />
                    <br />
                    <i>{error.statusText || error.message}</i>
                  </p>
                </div>
              </div>
            </section>
          </div>
        </div>
      </div>
    </div>
  );
}
