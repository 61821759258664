import { TextBlock } from '../parts';

export function Faq() {
  return (
    <>
      <TextBlock
        title="Is Pathtraq a map app?"
        subtitle="No. Use map apps for maps."
        text="Pathtraq displays maps to show your current location, the location of check in, and the route you've taken. However, Pathtraq doesn't provide information about places or offer navigation. For those features, you should use other map apps in addition to Pathtraq."
      />

      <TextBlock
        title="Where is data stored?"
        subtitle="Our serivce platform, not on the device."
        text="Tracking data should be stored for an extended duration, longer than the typical lifespan of mobile devices. Currently, mobile devices lack the necessary processing power to handle various Pathtraq features. Therefore, Pathtraq stores user data on our service platform, which is located in Japan."
      />

      <TextBlock
        title="Are our data safe? What does Pathtraq on our data?"
        subtitle="You own your data. Privacy and Security are our top priority."
        text="The data, including tracks, belongs to the users. We safeguard your data with industry-standard security measures and also prioritize your privacy through the opt-in policy for any new features that involve your data."
      />

      <TextBlock
        title="How about Pathtraq Android app?"
        subtitle="Nothing planned for now."
        text="There is no Android app engineers in our team. Are you a very good Android app engineer? Contact us!"
      />

      <TextBlock
        title="My map app has Timeline. Why Pathtraq?"
        subtitle="Features, and Privacy."
        text="A simple 'Timeline' alone cannot offer features such as area search, check-ins, sharing check-ins on social media, and our upcoming new features. Pathtraq safeguards your location information from ad technologies operated by such 'Timeline' providers."
      />
    </>
  );
}
