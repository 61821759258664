import {useState, useEffect} from 'react'
import ReactMarkdown from 'react-markdown'
import remarkGfm from 'remark-gfm'

import { h2component, h3component, tableComponent } from './markdown_helper'

export default function DisplayMarkdown(params: {title: string, url: string, changes: string}) {
  const { title, url, changes } = params;
  const [markdownText, setMarkdownText] = useState('Now loading');

  useEffect(() => {
    fetch(url)
      .then((response) => response.text())
      .then((text) => {
        setMarkdownText(text);
      });
  }, [title]);

  return (
    <>
      <div className="container is-widescreen" style={{paddingBottom: '1em'}}>
        <div className="markdown notification">
          <ReactMarkdown
            remarkPlugins={[remarkGfm]}
            components={{
              h2: h2component,
              h3: h3component,
              table: tableComponent,
            }}
            children={markdownText}
          />
        </div>
      </div>
      <footer className="footer">
        <div className="content has-text-centered">
          <p>
            <strong>{title}</strong> of Pathtraq. All rights reserved. The changes of this is available <a href={changes}>here</a>.
          </p>
        </div>
      </footer>
    </>
  );
}
