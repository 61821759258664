import PhoneFace from '../phone_face';
import { Section, TextBlock } from '../parts';

export function Concept() {
  return (
    <>
      <Section
        image="right"
        title="Track your whole life."
        subtitle="Pathtraq records your location in 24hours, 365days."
        text="You will never lose your memory. Locations and paths are automatically collected and stored safely."
      >
        <PhoneFace src="/app_main.png" />
      </Section>

      <Section
        image="left"
        title="Display your past track."
        subtitle="Your past tracks are always available."
        text="Where was it? What's the place I visited at that time? How long time did it take from A to B? Everything is clear on your tracks."
      >
        <PhoneFace src="/app_view_track.png" />
      </Section>

      <Section
        image="right"
        title="Remember when you were."
        subtitle="Are search revives your memory."
        text="When did I visit the place at last? How many times I went there? Area search shows and renews your precious memories."
      >
        <PhoneFace src="/app_area_search.png" />
      </Section>

      <Section
        image="left"
        title="Check in with memories, and share."
        subtitle="You check in at places, then share it."
        text="Love the place? Let's check in at there. Photos enrich the experience. Shared check ins on SNSs show what you are doing."
      >
        <PhoneFace src="/app_view_checkin.png" />
      </Section>

      <div style={{textAlign: 'center'}}>
        <TextBlock
          title="Start tracking!"
          subtitle="Earlier is better."
          text="Many tracks, long history. Data empowers you. Install the Pathtraq app now!"
        />
        <a href="https://apps.apple.com/us/app/pathtraqapp/id6470182636?itsct=apps_box_badge&amp;itscg=30200" style={{display: "inline-block", overflow: "hidden", borderRadius: "13px", width: "186px", height: "62px"}}>
          <img src="https://tools.applemediaservices.com/api/badges/download-on-the-app-store/black/en-us?size=250x83&amp;releaseDate=1698192000" alt="Download on the App Store" style={{borderRadius: "13px", width: "186px", height: "62px"}} />
        </a>
      </div>
    </>
  );
}
