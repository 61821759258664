import { useLoaderData } from "react-router-dom";
import { useState } from 'react'
import { cancelDeletingUserUrl } from '../api';

async function executeCancel(token: string, callback: (r: boolean, e: string | null) => void) {
  const url = cancelDeletingUserUrl(token, new URL(document.location.href));
  try {
    const response = await fetch(url, {method: 'POST', body: ''});
    callback(response.ok, null);
  } catch (error) {
    if (error instanceof Error) {
      callback(false, `Failed to cancel (${error.message}), URL: ${url}`);
    } else if (typeof error === 'string') {
      callback(false, `Failed to cancel (${error}), URL: ${url}`);
    } else {
      callback(false, `Failed unexpectedly (${error}), URL: ${url}`);
    }
  }
}

function ButtonComponent(params: {executing: boolean, cancelled: boolean, onClick: () => void}) {
  if (params.executing) {
    return (
      <button className="button is-danger is-loading">Cancel deleting the user</button>
    );
  }
  if (params.cancelled) {
    return (
      <button className="button is-success">Cancelled</button>
    );
  }
  return (
    <button className="button is-danger" onClick={params.onClick}>Cancel deleting the user</button>
  );
}

export async function loader({ params }: any) {
  return {token: params.token}
}

interface CancelTokenData {
  token: string;
}

export function DeletingUserOperation() {
  const { token } = useLoaderData() as CancelTokenData;
  const [executing, setExecuting] = useState<boolean>(false);
  const [cancelled, setCancelled] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);

  const cancelCallback = (r: boolean, e: string | null) => {
    setExecuting(false);
    setCancelled(r);
    if (e === null) {
      setError(null);
    } else {
      setError(e);
    }
  };

  const onClickHandler = () => {
    setExecuting(true);
    executeCancel(token, cancelCallback);
  };

  return (<div className="container is-max-desktop">
    <div className="box">
      <div>
        The request to delete the user can be cancelled.
      </div>
      <div style={{paddingTop: '3em'}}>
        <ButtonComponent executing={executing} cancelled={cancelled} onClick={onClickHandler} />
        <div style={{color: 'red'}}>{ error }</div>
      </div>
    </div>
  </div>);
};


