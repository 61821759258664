import { Link } from "react-router-dom";

export function AboutUs() {
  return (
    <section className="section is-large has-text-white">
      <h1 className="title has-text-white">Pathtraq</h1>
      <h2 className="subtitle has-text-white">
        Pathtraq is the service provided/operated by<br />
        Satoshi Tagomori (a.k.a., tagomoris), a software engineer in Japan.
      </h2>
      <div className="content">
        <dl>
          <dt>Email</dt><dd>tagomoris@gmail.com</dd>
          <dt>Contact</dt><dd>Use <Link to="/report_issues" style={{color: "skyblue"}}>the form</Link></dd>
        </dl>
      </div>
    </section>
  );
}
