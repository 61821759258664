import { Outlet, Link } from "react-router-dom";

export default function Layout() {
  let messageBox = "";
  let burgerAction = () => {
    let burger = document.getElementById('navbarBurger');
    let menu = document.getElementById('navbarLinks');
    if (burger === null || menu === null) {
      return;
    }
    if (burger.classList.contains('is-active')) {
      burger.classList.remove('is-active');
      menu.classList.remove('is-active');
    } else {
      burger.classList.add('is-active');
      menu.classList.add('is-active');
    }
  };
  let navItemAction = (e: React.MouseEvent<HTMLElement>) => {
    let focusing = document.activeElement;
    if (focusing !== null && focusing.classList.contains('navbar-item')) {
      // Hide the dropdown on desktop after clicking link items
      (focusing as HTMLElement)?.blur();
    }
    let burger = document.getElementById('navbarBurger');
    let menu = document.getElementById('navbarLinks');
    if (burger?.classList.contains('is-active')) {
      // Close the burger menu on mobile after tapping link items
      burger.classList.remove('is-active');
      menu?.classList.remove('is-active');
    }
    // Do not 'preventDefault' to navigate to the Link destination
  };
  return (
    <div>
      <nav className="navbar is-success is-fixed-top" role="navigation" aria-label="main navigation" style={{ backgroundColor: '#004225' }}>
        <div className="navbar-brand">
          <a className="navbar-item" href="/">
            <img src="/pathtraqapp.png" style={{border: "1px solid #ffffff", borderRadius: "5px", width: "28px", height: "28px"}} />
          </a>
        </div>
        <div id="navbarLinks" className="navbar-menu">
          <div className="navbar-start">
            <Link to="/service/concept" className="navbar-item" onClick={navItemAction}>Concept</Link>
            <Link to="/service/faq" className="navbar-item" onClick={navItemAction}>FAQ</Link>
            <div id="navbarDropdown" className="navbar-item has-dropdown is-hoverable">
              <a className="navbar-link" href="#todo">More</a>
              <div className="navbar-dropdown">
                <Link to="/service/account-lifecycle" className="navbar-item" onClick={navItemAction}>Account Lifecycle</Link>
                <Link to="/privacy/data-visibility" className="navbar-item" onClick={navItemAction}>Data Visibility</Link>
                <Link to="/service/policy-overview" className="navbar-item" onClick={navItemAction}>Policies Overview</Link>
                <Link to="/service/terms-of-use" className="navbar-item" onClick={navItemAction}>Terms of Use</Link>
                <Link to="/privacy/privacy-policy" className="navbar-item" onClick={navItemAction}>Privacy Policy</Link>
                <hr className="navbar-divider" />
                <Link to="/about_us" className="navbar-item" onClick={navItemAction}>About Us</Link>
                <Link to="/report_issues" className="navbar-item" onClick={navItemAction}>Report issues</Link>
              </div>
            </div>
          </div>
        </div>
        <div className="navbar-end">
          <a role="button" id="navbarBurger" className="navbar-burger" aria-label="menu" aria-expanded="false" onClick={burgerAction}>
            <span aria-hidden="true"></span>
            <span aria-hidden="true"></span>
            <span aria-hidden="true"></span>
          </a>
        </div>
      </nav>
      <div style={{ backgroundColor: '#004225' }}>
        <div style={{ height: '5vh' }}>  </div>
        <div>{messageBox}</div>
        <div id="detail" style={{paddingBottom: '5vh'}}>
          <Outlet />
        </div>
      </div>
      <div style={{textAlign: 'center'}}>
        Pathtraq, provided by tagomoris
      </div>
    </div>
  );
}
