import React from 'react';
import ReactDOM from 'react-dom/client';
import {
    createBrowserRouter,
    RouterProvider,
} from "react-router-dom";

import './index.css';

import Layout from './layout';
import NotFoundPage from './not_found';

import DisplayMarkdown from './display_markdown';

import { AboutUs } from './routes/about_us';
import { Checkin, loader as checkinLoader } from './routes/checkin';
import { Concept } from './routes/concept';
import { DeletingUserOperation, loader as deletingUserTokenLoader } from './routes/deleting_user_operation';
import { Faq } from './routes/faq';
import { ReportIssues } from './routes/report_issues';
import Top from './routes/top';

function raw(doc: string): string {
  return `https://raw.githubusercontent.com/pathtraq/public_policies/main/${doc}.md`;
}

function commits(doc: string): string {
  return `https://github.com/pathtraq/public_policies/commits/main/${doc}.md`;
}

const router = createBrowserRouter([
  {
    path: "/",
    element: <Layout />,
    errorElement: <NotFoundPage />,
    children: [
      {
        path: "/",
        element: <Top />,
      },
      {
        path: "/service/concept",
        element: <Concept />,
      },
      {
        path: "/service/faq",
        element: <Faq />,
      },
      {
        path: "/privacy/data-visibility",
        element: <DisplayMarkdown title="Data Visibility" url={raw('data_visibility')} changes={commits('data_visibility')} />,
      },
      {
        path: "/privacy/privacy-policy",
        element: <DisplayMarkdown title="Privacy Policy" url={raw('privacy_policy')} changes={commits('privacy_policy')} />,
      },
      {
        path: "/service/account-lifecycle",
        element: <DisplayMarkdown title="Account Lifecycle" url={raw('account_lifecycle')} changes={commits('account_lifecycle')} />,
      },
      {
        path: "/service/policy-overview",
        element: <DisplayMarkdown title="Policies Overview" url={raw('policies_overview')} changes={commits('policies_overview')} />,
      },
      {
        path: "/service/terms-of-use",
        element: <DisplayMarkdown title="Terms of Use" url={raw('terms_of_use')} changes={commits('terms_of_use')} />,
      },
      {
        path: "/about_us",
        element: <AboutUs />,
      },
      {
        path: "/report_issues",
        element: <ReportIssues />,
      },
      // Not linked from the public Web pages
      {
        path: "/checkin/:checkinId",
        element: <Checkin />,
        loader: checkinLoader,
      },
      {
        path: "/a/deleting_user/:token",
        element: <DeletingUserOperation />,
        loader: deletingUserTokenLoader,
      }
    ],
  },
]);

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <React.StrictMode>
    <RouterProvider router={router} />
  </React.StrictMode>
);
