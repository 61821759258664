const generateSlug = (source: string) => {
  let str = source.replace(/^\s+|\s+$/g, "");
  str = str.toLowerCase();
  str = str
    .replace(/[^a-z0-9 -]/g, "")
    .replace(/\s+/g, "-")
    .replace(/-+/g, "-");
  return str;
};

interface H2Props {
  children: React.ReactNode;
}
interface H3Props {
  children: React.ReactNode;
}
interface TableProps {
  children: React.ReactNode;
}

export const h2component = (props: H2Props) => {
  const children = Array.isArray(props.children) ? props.children : [props.children];
  const heading = children.flatMap((element) => {
    if (typeof element === 'string') {
      return element;
    } else if (element?.type !== undefined && typeof element.props.children === 'string') {
      return element.props.children;
    } else {
      return []
    }
  }).join('');
  const slug = generateSlug(heading);
  return (
    // eslint-disable-next-line
    <h2 id={slug} {...props}></h2>
  );
};

export const h3component = (props: H3Props) => {
  const children = Array.isArray(props.children) ? props.children : [props.children];
  const heading = children.flatMap((element) => {
    if (typeof element === 'string') {
      return element;
    } else if (element?.type !== undefined && typeof element.props.children === 'string') {
      return element.props.children;
    } else {
      return []
    }
  }).join('');
  const slug = generateSlug(heading);
  return (
    // eslint-disable-next-line
    <h3 id={slug} {...props}></h3>
  );
};

export const tableComponent = (props: TableProps) => {
  return (
    <table className="table is-hoverable" {...props}></table>
  );
};
