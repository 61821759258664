import React from 'react';

type textBlockProps = {
  title: string,
  subtitle: string,
  text?: string | null,
};

export function TextBlock(props: textBlockProps) {
  return (
    <section className="section is-medium" style={{color: "white"}}>
      <h1 className="title" style={{color: "white"}}>{props.title}</h1>
      <h2 className="subtitle" style={{color: "white"}}>{props.subtitle}</h2>
      <div>
        {props.text ?? ''}
      </div>
    </section>
  );
}

type imageAt = 'right' | 'left';
type sectionProps = {
  image: imageAt,
  title: string,
  subtitle: string,
  text?: string | null,
  children: React.ReactNode,
};

export function Section(props: sectionProps) {
  if (props.image === 'right') {
    return (
    <div className="columns" style={{margin: '3em'}}>
      <div className="column">
        <TextBlock title={props.title} subtitle={props.subtitle} text={props.text} />
      </div>
      <div className="column">
        {props.children}
      </div>
    </div>
    );
  }
  return (
    <div className="columns" style={{margin: '3em'}}>
      <div className="column" style={{textAlign: 'center'}}>
        {props.children}
      </div>
      <div className="column">
        <TextBlock title={props.title} subtitle={props.subtitle} text={props.text} />
      </div>
    </div>
  )
}
