import { useState } from 'react'
import { useLoaderData } from "react-router-dom";
import { checkinDataUrl } from '../api';
import { LabelM18N, ProfileImage, UserPhoto, CheckinData, ApiResponse } from '../models';
import { Map, LatLng } from '../map';
import { PhotoTiles } from '../photo_tiles'
import { PhotoOverlay } from '../photo_overlay'

function textInLang(label: LabelM18N, lang: string | null): string {
  if (lang && lang in label.e) {
    return label.e[lang];
  }
  return label.e[label.d];
}

function dateInShort(date: Date): string {
  const str = date.toString();
  return str.split(/ GMT[-+]/)[0]
}

function profileImageUrl(profileImage: ProfileImage): string {
  if (profileImage.storage === "images_public") {
    return "https://image.pathtraq.tagomor.is/" + profileImage.data_key;
  }
  return "";
}

function googleMapsLink(point: LatLng): string {
  return `https://www.google.com/maps/@${point.lat},${point.lng},15z?q=${point.lat},${point.lng}`
}

export async function loader({ params }: any) {
  const checkinId = params.checkinId;
  const url = new URL(document.location.href);
  const lang = url.searchParams.get('lang')

  const container = document.getElementById('content-data-container');
  if (container) {
    const json = container.dataset.contentJson;
    if (json) {
      const obj = JSON.parse(json);
      if (obj.data_type === 'checkin_data' && obj.checkin.ulid === checkinId) {
        obj.checkinId = checkinId;
        obj.lang = lang;
        return { data: obj };
      }
    }
  }
  const response = await fetch(checkinDataUrl(url));
  const resp: ApiResponse<CheckinData> = await response.json(); 
  const data = resp.result;
  data.checkinId = checkinId;
  data.lang = lang;
  
  return { data };
}

interface LoadedCheckinData {
  data: CheckinData;
}

function ProfileImageColumn(props: {profileImage: ProfileImage | null}) {
  if (props.profileImage === null) {
    return (
      <div className="column is-one-quarter">
      </div>
    );
  }
  return (
    <div className="column is-one-quarter">
      <figure className="image is-128x128">
        <img className="is-rounded" style={{border: 'solid 5px #004225'}} src={profileImageUrl(props.profileImage)} alt="the user profile" />
      </figure>
    </div>
  );
}

export function Checkin() {
  const { data } = useLoaderData() as LoadedCheckinData;

  const [viewPhoto, setViewPhoto] = useState<UserPhoto | null>(null);

  const placeName = textInLang(data.place.name, data.lang);
  const placeVicinity = textInLang(data.place.vicinity, data.lang);
  const username = data.user.name;

  const checkedInAt = {
    lat: data.checkin.lat,
    lng: data.checkin.lng,
  };
  const place = {
    lat: data.place.lat,
    lng: data.place.lng,
  }

  const onClickTileCallback = (photo: UserPhoto) => {
    setViewPhoto(photo);
  };
  const toCloseCallback = () => {
    setViewPhoto(null);
  };

  return (
    <>
      <div className="container notification" style={{padding: '0px', maxWidth: '380px', minWidth: '380px', borderRadius: '10px'}}>
        <div id="map" style={{borderRadius: '10px', paddingBottom: '0px', marginBottom: '0px'}}>
          <Map position={checkedInAt} place={place} zoom={ 15 } width="380px" height="200px" />
        </div>

        <div className="columns is-mobile" style={{paddingTop: 0, paddingBottom: 5, paddingLeft: 3, paddingRight: 3}}>
          <ProfileImageColumn profileImage={data.profile_image} />

          <div className="column is-three-quarter">
            <div className="content is-small" style={{paddingTop: '0px', paddingBottom: '0px', paddingRight: '5px', textAlign: 'right', margin: 0}}>
              Checked in at: <img src="https://maps.google.com/mapfiles/ms/micons/red-pushpin.png" alt="red push" height="10" width="14" />,
              Place locates at: <img src="https://maps.google.com/mapfiles/ms/micons/blue-dot.png" alt="blue dot" height="10" width="14" />
            </div>

            <div className="subtitle is-4" style={{paddingLeft: 10, paddingBottom: 5, margin: 0}}>
              <span style={{fontFamily: 'sans-serif', fontWeight: 'bold', color: '#ffffff', backgroundColor: '#004225', paddingLeft: 20, paddingRight: 20, borderRadius: 10}}>
                { username }
              </span>
            </div>

            <div className="subtitle is-5" style={{paddingTop: 5, margin: 0, textAlign: 'right'}}>at { placeName }</div>
          </div>
        </div>

        <div style={{textAlign: 'right', paddingTop: 20, paddingRight: 3}}>
          <p style={{paddingBottom: '1vh'}}>
            { placeVicinity } <br />
            <a className="content is-small" href={googleMapsLink(place)} target="_blank" rel="noreferrer">Open Google Maps</a>
          </p>
          <p>{ dateInShort(new Date(data.checkin.ts * 1000)) }</p>
        </div>

        <PhotoTiles photos={data.photos} width={ 380 } onClickTile={onClickTileCallback} />
      </div>
      { viewPhoto ? <PhotoOverlay photo={viewPhoto} toClose={toCloseCallback} /> : <></> }
    </>
  );
}
