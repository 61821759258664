import React, { useState, useCallback, useRef } from 'react';
import { LoadScript, GoogleMap, MarkerF } from "@react-google-maps/api";

import { googleMapsApiKey } from './constants/apikey';

const mapOptions = {
  // styles: mapStyles,
  disableDefaultUI: true, // disable options for satellite view, etc
  zoomControl: true,
};

export interface LatLng {
  lat: number;
  lng: number;
}

export interface Place {
  name: string;
  position: LatLng;
}

export interface MapProps {
  position: LatLng;
  place: LatLng;
  zoom: number;
  height: string;
  width: string;
  // onCenterChanged: (lat: number, lng: number) => null;
  // onZoomChanged: (zoom: number) => null;
}

export function Map(props: MapProps) {
  const center = props.position;
  const place = props.place;
  const zoom = props.zoom;
  const containerStyle = {
    height: props.height,
    width: props.width,
    borderRadius: '10px',
  };

  // This is useful when caller can modify markers, to keep the current center/zoom even when
  // props are updated and the view is re-drawn.
  // Currently, it's impossible, so those callbacks are not useless.

  // const onCenterChangedCallback = props.onCenterChanged;
  // const onZoomChangedCallback = props.onZoomChanged;

  const [map, setMap] = useState(null);

  const mapRef = useRef<any>(map);
  const onLoad = useCallback((loaded: any) => {
    setMap(loaded);
    mapRef.current = loaded;
  }, [])

  const onUnmount = useCallback(() => {
    setMap(null);
  }, [])

  const onCenterChanged = () => {
    // const map = mapRef.current;
    // if (map) {
    //   const c = map.getCenter() as any;
    //   onCenterChangedCallback(c.lat(), c.lng());
    // }
  }

  const onZoomChanged = () => {
    // const map = mapRef.current;
    // if (map) {
    //   onZoomChangedCallback(map.getZoom());
    // }
  }

  return (
    <LoadScript googleMapsApiKey={googleMapsApiKey}>
      <GoogleMap
        id="mainMap"
        mapContainerStyle={containerStyle}
        zoom={zoom}
        center={center}
        options={mapOptions}
        onLoad={onLoad}
        onUnmount={onUnmount}
        onCenterChanged={onCenterChanged}
        onZoomChanged={onZoomChanged}
      >
        <MarkerF position={center} icon="https://maps.google.com/mapfiles/ms/micons/red-pushpin.png" />
        <MarkerF position={place} icon="https://maps.google.com/mapfiles/ms/micons/blue-dot.png" />
      </GoogleMap>
    </LoadScript>
  );
}

export default React.memo(Map);
