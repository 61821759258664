import PhoneFace from '../phone_face';

export default function Top() {
  return (
    <div className="container is-max-desktop" style={{ width: '100%', height: '100vh' }}>
      <section className="hero is-fullheight-with-navbar">
        <div className="hero-body">
          <div className="columns">
            <div className="column">
              <img src="/pathtraq_trans.png" alt="Pathtraq" />
              <p className="subtitle has-text-white" style={{paddingLeft: "1em"}}>
                Track your own life.
              </p>
              <div>
                <a href="https://apps.apple.com/us/app/pathtraqapp/id6470182636?itsct=apps_box_badge&amp;itscg=30200" style={{display: "inline-block", overflow: "hidden", borderRadius: "13px", width: "186px", height: "62px"}}>
                  <img src="https://tools.applemediaservices.com/api/badges/download-on-the-app-store/black/en-us?size=250x83&amp;releaseDate=1698192000" alt="Download on the App Store" style={{borderRadius: "13px", width: "186px", height: "62px"}} />
                </a>
              </div>
            </div>
            <div className="column">
              <PhoneFace src="/app_view_track.png" />
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}
