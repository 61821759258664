export interface LabelM18N {
  d: string;
  e: { [lang: string]: string; }
}

interface Checkin {
  ulid: string;
  ts: number;
  lat: number;
  lng: number
}

interface Place {
  uuid: string;
  google_place_id: string;
  name: LabelM18N;
  vicinity: LabelM18N;
  lat: number;
  lng: number;
  updated_at: number;
}

interface User {
  name: string;
}

export type Orientation = "portrait" | "landscape" | "square" | ""

export interface UserPhoto {
  uuid: string;
  storage: string;
  data_key: string;
  orientation: Orientation;
}

export type PhotoThumbnailType = "portrait" | "portraitNarrow" | "landscape" | "landscapeNarrow" | "square" | "squareMedium" | "squareSmall"

function dataKeyOnlyId(photo: UserPhoto): string {
  let parts = photo.data_key.split("/", 2);
  if (parts.length !== 2) {
    return photo.data_key;
  }
  return parts[1];
}

export function userPhotoViewURL(photo: UserPhoto): string {
  const id = dataKeyOnlyId(photo);
  return `https://image.pathtraq.tagomor.is/photo_m/${id}`;
}

export function userPhotoThumbnailURL(photo: UserPhoto, type: PhotoThumbnailType): string {
  const id = dataKeyOnlyId(photo);
  let photoType: string;
  switch(type) {
    case "portrait":        photoType = "photo_p1"; break;
    case "portraitNarrow":  photoType = "photo_p2"; break;
    case "landscape":       photoType = "photo_l1"; break;
    case "landscapeNarrow": photoType = "photo_l2"; break;
    case "square":          photoType = "photo_s1"; break;
    case "squareMedium":    photoType = "photo_s2"; break;
    case "squareSmall":     photoType = "photo_s3"; break;
  }
  return `https://image.pathtraq.tagomor.is/${photoType}/${id}`;
}

export interface ProfileImage {
  storage: string;
  data_key: string;
}

export interface CheckinData {
  checkin: Checkin;
  photos: [UserPhoto];
  place: Place;
  user: User;
  profile_image: ProfileImage;

  // added on client side
  checkinId: string;
  lang: string | null;
}

export interface ApiResponse<T> {
  result: T;
  status: string;
  // Client code does not touch error/notifications
  error: any;
  notifications: [any];
}
