import { MouseEvent } from 'react'
import { UserPhoto, userPhotoViewURL } from './models';

interface PhotoOverlayProps {
  photo: UserPhoto;
  toClose: () => void;
}

export function PhotoOverlay(props: PhotoOverlayProps) {
  const photo = props.photo;
  const toCloseCallback = props.toClose;
  const tapCancel = (event: MouseEvent<HTMLImageElement>) => { event.stopPropagation(); };
  return (
    <div
      style={{position: 'fixed', top: 0, left: 0, width: '100%', height: '100%', backgroundColor: 'rgba(0,0,0,0.75)', display: 'flex', alignItems: 'center', justifyContent: 'center'}}
      onClick={toCloseCallback}
    >
      <figure style={{padding: 0, margin: 0}}>
        <img src={userPhotoViewURL(photo)} alt="The focused one" style={{padding: 0, margin: 0}} onClick={tapCancel} />
        <div style={{padding: 0, margin: 0, textAlign: 'right'}}><span style={{WebkitAppearance: 'none', backgroundColor: '#ffffff', border: 'solid 1px #ffffff', cursor: 'pointer'}} onClick={toCloseCallback}>&#10006;</span></div>
      </figure>
    </div>
  );
}
