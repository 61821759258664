import { UserPhoto, Orientation, userPhotoThumbnailURL, userPhotoViewURL, PhotoThumbnailType } from './models';

interface PhotoTilesProps {
  photos: UserPhoto[],
  width: number,
  onClickTile: (photo: UserPhoto) => void;
}

type Height = number | "h" | null
type Width = number | "w" | null

function thumbnailTypeOf(orientation: Orientation): PhotoThumbnailType {
  switch(orientation) {
    case "landscape": return "landscape";
    case "portrait": return "portrait";
    default:
      return "square";
  }
}

function h(n: number): Height { return Math.floor(n); }
function w(n: number): Width { return Math.floor(n); }
function n(n: number | string | null): number { return n as number }

function image(photo: UserPhoto, type: PhotoThumbnailType, onClick: (photo: UserPhoto) => void, height: Height = null, width: Width = null, url: string | null = null) {
  const imageUrl = url ?? userPhotoThumbnailURL(photo, type);
  const callback = () => { onClick(photo); };
  if (height && width) {
    // <figure class="image"> specifies a css "width: '100%'" on <img>
    // and it overwrites "<img width>" attribute. (CSS overwrites <img width>)
    // So, it's not good here.
    // In this case, "display: 'block'" is required to not have additional vertical margin
    // under the image (between images).
    return (
      <figure style={{padding: '1px', margin: '0px'}}>
        <img src={imageUrl} alt="tile" height={ `${Math.floor(n(height))}px` } width={ `${Math.floor(n(width))}px` } style={{display: 'block'}} onClick={callback} />
      </figure>
    );
  }

  return (
    <figure className="image" style={{padding: '1px'}}>
      <img src={imageUrl} alt="tile" onClick={callback} />
    </figure>
  );
}

function layout1(p0: UserPhoto, onClick: (photo: UserPhoto) => void) {
  return image(p0, thumbnailTypeOf(p0.orientation), onClick, null, null, userPhotoViewURL(p0));
}

function layout2(p0: UserPhoto, p1: UserPhoto, onClick: (photo: UserPhoto) => void) {
  if (p0.orientation === p1.orientation) {
    if (p0.orientation === "landscape") {
      const images = [
        image(p0, "landscape", onClick),
        image(p1, "landscape", onClick),
      ];
      return (<>{ images }</>);
    } else if (p0.orientation === "portrait") {
      return (
        <div className="columns">
          <div className="column is-one-second" style={{padding: '0px'}}>
            { image(p0, "portrait", onClick) }
          </div>
          <div className="column is-one-second" style={{padding: '0px'}}>
            { image(p1, "portrait", onClick) }
          </div>
        </div>
      );
    }
  }
  // other, square-square
  return (
    <div className="columns">
      <div className="column is-one-second" style={{padding: '0px'}}>
        { image(p0, "square", onClick) }
      </div>
      <div className="column is-one-second" style={{padding: '0px'}}>
        { image(p1, "square", onClick) }
      </div>
    </div>
  );
}

function layout3(p0: UserPhoto, rest: UserPhoto[], width: number, onClick: (photo: UserPhoto) => void) {
  let unit: number;
  switch (p0.orientation) {
    case "landscape":
      unit = width / 16.0;
      return (
        <>
          <div style={{paddingBottom: '0px'}}>
            { image(p0, "landscapeNarrow", onClick, h(unit * 9), w(unit * 16)) }
          </div>
          <div style={{display: 'flex'}}>
            <div style={{padding: '0px'}}>
              { image(rest[0], "squareMedium", onClick, h(unit * 8), w(unit * 8)) }
            </div>
            <div style={{padding: '0px'}}>
              { image(rest[1], "squareMedium", onClick, h(unit * 8), w(unit * 8)) }
            </div>
          </div>
        </>
      );
    case "portrait":
      unit = width / (9.0 + 16.0 / 2.0);
      return (
        <div style={{display: 'flex'}}>
          <div style={{padding: '0px'}}>
            { image(p0, "portraitNarrow", onClick, h(unit * 16), w(unit * 9)) }
          </div>
          <div style={{padding: '0px'}}>
            { image(rest[0], "squareMedium", onClick, h(unit * 8), w(unit * 8)) }
            { image(rest[1], "squareMedium", onClick, h(unit * 8), w(unit * 8)) }
          </div>
        </div>
      );
  }
  unit = width / 2.0;
  return (
    <>
      <div style={{paddingBottom: '0px'}}>
        { image(p0, "square", onClick, h(unit * 2), w(unit * 2)) }
      </div>
      <div style={{display: 'flex'}}>
        <div style={{padding: '0px'}}>
          { image(rest[0], "squareMedium", onClick, h(unit), w(unit)) }
        </div>
        <div style={{padding: '0px'}}>
          { image(rest[1], "squareMedium", onClick, h(unit), w(unit)) }
        </div>
      </div>
    </>
  );
}

function layout4(p0: UserPhoto, rest: UserPhoto[], width: number, onClick: (photo: UserPhoto) => void) {
  let unit: number;
  switch (p0.orientation) {
    case "landscape":
      unit = width / 48.0;
      return (
        <>
          <div style={{paddingBottom: '0px'}}>
            { image(p0, "landscapeNarrow", onClick, h(unit * 27), w(unit * 48)) }
          </div>
          <div style={{display: 'flex'}}>
            <div style={{padding: '0px'}}>
              { image(rest[0], "squareMedium", onClick, h(unit * 16), w(unit * 16)) }
            </div>
            <div style={{padding: '0px'}}>
              { image(rest[1], "squareMedium", onClick, h(unit * 16), w(unit * 16)) }
            </div>
            <div style={{padding: '0px'}}>
              { image(rest[2], "squareMedium", onClick, h(unit * 16), w(unit * 16)) }
            </div>
          </div>
        </>
      );
    case "portrait":
      unit = width / (9.0 + 16.0 / 3.0);
      return (
        <div style={{display: 'flex'}}>
          <div style={{padding: '0px'}}>
            { image(p0, "portraitNarrow", onClick, h(unit * 16), w(unit * 9)) }
          </div>
          <div style={{padding: '0px'}}>
            { image(rest[0], "squareMedium", onClick, h(unit * 5.3), w(unit * 5.3)) }
            { image(rest[1], "squareMedium", onClick, h(unit * 5.3), w(unit * 5.3)) }
            { image(rest[2], "squareMedium", onClick, h(unit * 5.3), w(unit * 5.3)) }
          </div>
        </div>
      );
  }
  unit = width / 3.0;
  return (
    <>
      <div style={{paddingBottom: '0px'}}>
        { image(p0, "square", onClick, h(width), w(width)) }
      </div>
      <div style={{display: 'flex'}}>
        <div style={{padding: '0px'}}>
          { image(rest[0], "squareMedium", onClick, h(unit), w(unit)) }
        </div>
        <div style={{padding: '0px'}}>
          { image(rest[1], "squareMedium", onClick, h(unit), w(unit)) }
        </div>
        <div style={{padding: '0px'}}>
          { image(rest[2], "squareMedium", onClick, h(unit), w(unit)) }
        </div>
      </div>
    </>
  );
}

function layout5(p0: UserPhoto, p1: UserPhoto, rest: UserPhoto[], width: number, onClick: (photo: UserPhoto) => void) {
  const unit = width / 5.0;
  return (
    <div style={{display: 'flex'}}>
      <div style={{padding: '0px'}}>
        { image(p0, "squareMedium", onClick, h(unit * 3), w(unit * 3)) }
        { image(p1, "squareMedium", onClick, h(unit * 3), w(unit * 3)) }
      </div>
      <div style={{padding: '0px'}}>
        { image(rest[0], "squareMedium", onClick, h(unit * 2), w(unit * 2)) }
        { image(rest[1], "squareMedium", onClick, h(unit * 2), w(unit * 2)) }
        { image(rest[2], "squareMedium", onClick, h(unit * 2), w(unit * 2)) }
      </div>
    </div>
  );
}

function layoutN(photos: UserPhoto[], onClick: (photo: UserPhoto) => void) {
  const tiles = [];
  for (const photo of photos) {
    tiles.push(
      image(photo, thumbnailTypeOf(photo.orientation), onClick, null, null, userPhotoViewURL(photo))
    );
  }
  return (<>{tiles}</>);
}

export function PhotoTiles(props: PhotoTilesProps) {
  const photos = props.photos;
  const onClickTile = props.onClickTile;
  switch(photos.length) {
    case 0: return (<></>);
    case 1: return layout1(photos[0], onClickTile);
    case 2: return layout2(photos[0], photos[1], onClickTile);
    case 3: return layout3(photos[0], photos.slice(1), props.width, onClickTile);
    case 4: return layout4(photos[0], photos.slice(1), props.width, onClickTile);
    case 5: return layout5(photos[0], photos[1], photos.slice(2), props.width, onClickTile);
  }
  return layoutN(photos, onClickTile);
}
