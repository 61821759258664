import { useState, FormEventHandler } from "react";
import { reportFeedbackUrl } from '../api';

async function sendFeedback(data: {name: string, email: string, category: string, description: string}): Promise<boolean> {
  const url = reportFeedbackUrl(new URL(document.location.href));
  try {
    const response = await fetch(url, {method: 'POST', body: JSON.stringify(data), headers:{'content-type': 'application/json'}});
    return response.ok;
  } catch (error) {
    return false;
  }
}

export function ReportIssues() {
  const [agreement, setAgreement] = useState(false);
  const [posting, setPosting] = useState(false);

  const checkboxAction = () => {
    setAgreement(!agreement);
  };

  const onSubmitAction: FormEventHandler<HTMLFormElement> = async (event) => {
    setPosting(true);
    event.preventDefault();
    const name = (event.target as any).name.value as string;
    const email = (event.target as any).email.value as string;
    const category = (event.target as any).category.value as string;
    const description = (event.target as any).description.value as string;
    let data = { name, email, category, description };
    const result = await sendFeedback(data);
    setPosting(false);
    if (result) {
      alert("Sent the feedback");
      document.location.reload();
    } else {
      alert("Failed to send the feedback");
    }
  };

  return (
    <form onSubmit={onSubmitAction}>
      <div className="has-white-text" style={{padding: "3em", backgroundColor: "white"}}>
        <div className="field">
          <label className="label">Your Name</label>
          <div className="control">
            <input className="input" type="text" name="name" placeholder="Your Name" />
          </div>
        </div>

        <div className="field">
          <label className="label">Email</label>
          <div className="control">
            <input className="input" type="email" name="email" placeholder="Email input" />
          </div>
          <p className="help is-success">We may respond to your email address.</p>
        </div>

        <div className="field">
          <label className="label">Subject</label>
          <div className="control">
            <div className="select">
              <select name="category">
                <option>Feature request</option>
                <option>Bug report</option>
                <option>Harassment</option>
                <option>Inappropriate content</option>
                <option>Other</option>
              </select>
            </div>
          </div>
        </div>

        <div className="field">
          <label className="label">Message</label>
          <div className="control">
            <textarea className="textarea" name="description" placeholder="Textarea"></textarea>
          </div>
        </div>

        <div className="field">
          <div className="control">
            <label className="checkbox">
              <input id="agreement-checkbox" type="checkbox" onChange={checkboxAction} value={agreement ? 'on' : 'off'} />
              <span style={{paddingLeft: "1em"}}>
                I agree to the <a href="/service/terms-of-use">Terms of Use</a> and <a href="/privacy/privacy-policy">Privacy Policy</a>.
              </span>
            </label>
          </div>
        </div>

        <div className="field is-grouped">
          <div className="control">
            <button className="button is-link" type="submit" disabled={!agreement || posting}>Submit</button>
          </div>
        </div>
      </div>
    </form>
  );
}
